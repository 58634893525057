import React, { useState } from 'react'
import Link from 'next/link'
import { useRouter } from 'next/router'
import { Form, Button, Row, Col, FormLabel, FormGroup, FormControl } from 'react-bootstrap'
import Message from '@/components/Message'
import Loader from '@/components/Loader'
import { getSession, signIn } from 'next-auth/react'
import FormContainer from '@/components/FormContainer'
import { wrapper } from '@/redux/store'

const LoginPage = ({ redirect }) => {
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState('')
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const router = useRouter();

    const submitHandler = async (e) => {
        e.preventDefault();
        setLoading(true);

        const result = await signIn('credentials', {
            redirect: false,
            email,
            password
        })

        setLoading(false)

        if (result.error) {
            setError(result.error)
        } else {
            router.push(redirect)
        }

    }
    return (
        <FormContainer md={4}>
            <h1>Sign In</h1>
            {error && <Message variant='danger'>{error}</Message>}
            {loading && <Loader />}
            <Form onSubmit={submitHandler}>
                <FormGroup className='mb-3' controlId='email'>
                    <FormLabel>Email Address</FormLabel>
                    <FormControl type='email' placeholder='Enter email' value={email} onChange={(e) => setEmail(e.target.value)}>

                    </FormControl>
                </FormGroup>
                <FormGroup className='mb-3' controlId='password'>
                    <FormLabel>Password</FormLabel>
                    <FormControl type='password' placeholder='Enter password' autoComplete="on" value={password} onChange={(e) => setPassword(e.target.value)}>

                    </FormControl>
                </FormGroup>
                <Button type='submit' variant='primary' style={{ width: '100%' }}>
                    Sign in
                </Button>
            </Form>
            <Row className='py-3'>
                <Col style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <div>New Customer? <Link href={redirect ? `/register?redirect=${redirect}` : '/register'}><a>Register</a></Link> </div>               <Link href="/password/forgot-password"><a>Forgot Password</a></Link>
                </Col>
            </Row>
        </FormContainer>
    )
}
export default LoginPage;


export const getServerSideProps = wrapper.getServerSideProps(store => async (context) => {
    const session = await getSession({ req: context.req });
    const redirect = context.query.redirect ? context.query.redirect : '/';

    if (session) {
        return {
            redirect: {
                destination: redirect,
                permanent: false
            }
        }
    }

    return {
        props: {
            redirect
        }
    }
})
